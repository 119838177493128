const ContactCard = ({ contact }) => {
    return (
        <div className='contact__card-box'>
            <div className='contact__card-info'>
                <i className={contact.icon}></i>
                <div>
                    <h3 className='contact__card-title'>
                        {contact.title}
                    </h3>
                    <p className='contact__card-description'>
                        {contact.description}
                    </p>
                </div>
            </div>
            <button className='button contact__card-button'>
                {contact.cta}
            </button>
        </div>
    )
}

export default ContactCard