import { MenuItem, Popover } from "@mui/material"
import { deleteProperty } from "../../Redux/actions/propertiesActions"
import DeleteModal from "../../Components/DeleteModal"
import EditProperty from "../../Components/Properties/EditProperty"
import Iconify from "../../Shared/iconify/Iconify"

export default function Crud({
    popper,
    handlePopper,
    handleModal,
    open,
    selectedProperty,
    dispatch,
    handleSuccess

}) {
    return (
        <>
            {open.showEdit &&
                <EditProperty
                    open={open.showEdit}
                    handleClose={() => handleModal('Edit')}
                    property={selectedProperty}
                    handleSuccess={handleSuccess}
                />
            }
            {open.showDelete &&
                <DeleteModal
                    open={open.showDelete}
                    handleClose={() => handleModal('Delete')}
                    property={selectedProperty}
                    handleDelete={() => {
                        dispatch(deleteProperty(selectedProperty._id, handleModal('Delete'), handleSuccess, dispatch))
                    }
                    }
                />
            }
            <Popover
                open={Boolean(popper)}
                anchorEl={popper}
                onClose={() => handlePopper()}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => handleModal('Edit')}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={() => handleModal('Delete')}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>
        </>
    )
}