import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useDispatch } from 'react-redux';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function Notifications({ isOpen, message, closeNotification,handleSuccess }) {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(isOpen);

    const handleClose = (event, reason) => {
        event.preventDefault();
        if (reason === 'clickaway') {
            setOpen(false);
        }
        dispatch(closeNotification(
            handleSuccess
        ))
        setOpen(false);
    };
    
    if (!isOpen) {
        return null
    }

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom', horizontal: 'right',
                }}
            >
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Stack>
    );
}