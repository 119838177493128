const SearchBar = ({ setSearchText, setSortby }) => {
  const handleChange = (event) => {
    setSearchText(event.target.value);
  };
  
  const sortby = (event) => {
    setSortby(event.target.value);
  };

  return (
    <div className='hero-form-wrapper'>
      <form action='' className='hero-form'>
        <div className='input-wrapper'>
          <label for='search' className='input-label'>
            Search
          </label>

          <input
            type='search'
            name='search'
            id='search'
            onChange={handleChange}
            placeholder='Search your home'
            required
            className='input-field'
          />

          <ion-icon name='search-outline'></ion-icon>
        </div>

        <div className='input-wrapper'>
          <label for='min-price' className='input-label'>
            Sort by
          </label>

          <select
            name='min-price'
            id='min-price'
            className='dropdown-list'
            onChange={sortby}
          >
            <option value='min price'>Sort by</option>
            <option value='price-ascending'>Low to High</option>
            <option value='price-descending'>High to Low</option>
            <option value='newest'>Most Recent</option>
          </select>
        </div>



      </form>
    </div>
  );
};

export default SearchBar;
