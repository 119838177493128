import { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import useResponsive from "../../Helpers/responsive";
import "./Nav.css";
import Logo from "../../Assets/logo.png";
const DropdownMenu = ({ handleShow, show, token }) => {

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant=''
        id='dropdown-basic'
        className='dropdown'

        style={{
          border: 0,
          fontSize: "20px",
        }}
      >

        <a
          className='header-cta-search ai-font-magnifying-glass-h '
          href='#menu'
          style={{
            fontSize: "20px",
          }}
          onClick={handleShow}
        >
          <button className="button_container" id="toggle" aria-controls="primary-menu" aria-expanded="false">
            <span className="top"></span>
            <span className="middle"></span>
            <span className="bottom"></span>
          </button>
        </a>
      </Dropdown.Toggle>

      <Dropdown.Menu className={
        show ? "active" : ""
      }>
        <Dropdown.Item href='/properties/shortTerm'>
          Short term rentals
        </Dropdown.Item>
        <Dropdown.Item href='/properties/longTerm'>
          Long term rentals
        </Dropdown.Item>
        <Dropdown.Item href='/properties/sale'>
          Own properties
        </Dropdown.Item>
        <Dropdown.Item href='/investIn'>Invest in</Dropdown.Item>
        <Dropdown.Item href='/otherServices'>Other services</Dropdown.Item>
        <Dropdown.Item href='/contactUs'>Contact Us</Dropdown.Item>
        {
          token &&
          <>
            <Dropdown.Item href='/dashboard/properties'>
              Properties
            </Dropdown.Item>
            <Dropdown.Item href='/contactList'>
              Contact List
            </Dropdown.Item>
            <Dropdown.Item href='/'
              onClick={() => {
                localStorage.removeItem("token");
              }}
            >
              Logout
            </Dropdown.Item>
          </>
        }
      </Dropdown.Menu>
    </Dropdown>
  );
};


const NavBar = () => {
  const getToken = localStorage.getItem("token");
  const [token, setToken] = useState();
  const [show, setShow] = useState(false);
  const isMobile = useResponsive();

  const handleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    setToken(getToken);
  }, [getToken]);

  return (
    <header id='header'>
      <div className='header-logo header-logo-text'>
        <a href='/'>
          <img src={Logo} alt='Logo' width='50' height='50' />
        </a>
      </div>

      {!isMobile && (
        <div
          className='header-nav'
          style={{
            marginTop: 11,
          }}
        >
          <nav className='navigation'>
            <div className='menu-main-menu-container'>
              <ul id='nav' className='menu'>
                <Dropdown>
                  <Dropdown.Toggle
                    variant=''
                    id='dropdown-basic'
                    style={{
                      display: "block",
                      position: "relative",
                      zIndex: 0,
                      padding: "30px 10px",
                      fontSize: "13px",
                      textAlign: "center",
                      textTransform: "uppercase",
                      whiteSpace: "nowrap",
                      border: 0,
                      color: "#fff",
                    }}
                  >
                    Rentals
                  </Dropdown.Toggle>

                  <Dropdown.Menu className='dropdown-transformation'>
                    <Dropdown.Item href='/properties/shortTerm'>
                      Short term
                    </Dropdown.Item>
                    <Dropdown.Item href='/properties/longTerm'>
                      Long term
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <li
                  id='menu-item-94'
                  className='menu-item menu-item-type-post_type menu-item-object-page menu-item-94'
                >
                  <a href="/properties/sale">Own properties</a>
                </li>
                <li
                  id='menu-item-93'
                  className='menu-item menu-item-type-post_type menu-item-object-page menu-item-93'
                >
                  <a href='/investIn'>Invest in</a>
                </li>
                <li
                  id='menu-item-43467'
                  className='menu-item menu-item-type-custom menu-item-object-custom menu-item-43467'
                >
                  <a href='/otherServices'>Other services</a>
                </li>
                <li
                  id='menu-item-43467'
                  className='menu-item menu-item-type-custom menu-item-object-custom menu-item-43467'
                >
                  <a href='/contactUs' data-title='Exclusive Properties'>
                    Contact Us
                  </a>
                </li>
                {
                  token &&
                  <>
                    <li
                      id='menu-item-43467'
                      className='menu-item menu-item-type-custom menu-item-object-custom menu-item-43467'
                    >
                      <a href='/dashboard/properties' data-title='Exclusive Properties'>
                        Properties List
                      </a>
                    </li>
                    <li
                      id='menu-item-43467'
                      className='menu-item menu-item-type-custom menu-item-object-custom menu-item-43467'
                    >
                      <a href='/contactList' data-title='Exclusive Properties'>
                        Contact List
                      </a>
                    </li>
                    <li
                      id='menu-item-43467'
                      className='menu-item menu-item-type-custom menu-item-object-custom menu-item-43467'
                    >
                      <a href='/'
                        onClick={() => {
                          localStorage.removeItem("token");
                        }}
                      >
                        Logout
                      </a>
                    </li>
                  </>
                }
              </ul>
            </div>
          </nav>
        </div>
      )}

      <div className='header-cta'>
        <a
          href='/properties/'
          className='header-cta-search ai-font-magnifying-glass-h'
          style={{
            fontSize: "20px",
          }}
        >
          <ion-icon name='search-outline'></ion-icon>
        </a>
        <a
          href='mailto:saberhlf@worldwidease.com'
          className='header-cta-email ai-font-envelope-o'
          style={{
            fontSize: "20px",
          }}
        >
          <ion-icon name='mail-outline'></ion-icon>
        </a>
        {isMobile && <DropdownMenu
          handleShow={handleShow}
          show={show}
          token={token}
        />}
      </div>
    </header>
  );
};

export default NavBar;
