import * as React from "react";
import PropTypes from "prop-types";
import { Box, Button, Container, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  maxWidth: {
    xs: '100%',
    sm: '100%',
    md: '100%',
    lg: '100%'
  },
  p: 4,
  maxHeight: {
    xs: '400px',
    sm: '600px',
    md: '400px',
    lg: '400px',
  }
};

DeleteModal.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};

export default function DeleteModal({ open, handleClose, handleDelete }) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box component='main' sx={style}>
        <Container maxWidth='sm'>
          <Typography variant='subtitle2' noWrap>
            Are you sure you want to delete ?
          </Typography>
          <Box sx={{ py: 2 }}>
            <Button
              color='primary'
              fullWidth
              size='large'
              type='button'
              variant='contained'
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Box>
        </Container>
      </Box>
    </Modal>
  );
}
