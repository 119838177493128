import { data } from "../../Data/contactUs";
import ContactCard from "./ContactCard";

const ContactUs = () => {

  return (
    <section className='contact section' id='contact'>
      <div className='container'>
        <div className='contact__container container grid'>
          <div className='contact__content'>
            <div className='contact__data'>
              <h2 className="h2 section-title pb-4">Contact Us</h2>
            </div>
            <div className='contact__card pt-2'>
              {data.map((item, index) => (
                  <ContactCard key={index} contact={item} />
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
