import ProductItem from "./ProductItem";

const ProductList = ({ productsList }) => {
   return (
    <ul className='property-list'>
      {productsList.map((product) => (
        <ProductItem key={product.id} product={product} />
      ))}
    </ul>
  );
};

export default ProductList;
