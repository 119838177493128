import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Grid, Card, Typography, TableCell, IconButton, CardContent } from '@mui/material';
import Iconify from '../../Shared/iconify';
import { fDate } from '../../Helpers/formatTime';

const StyledCardMedia = styled('div')({
  position: 'relative',
  paddingTop: 'calc(100% * 3 / 4)',
});

const StyledCover = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

PropertyCard.propTypes = {
  property: PropTypes.object.isRequired,
};

export default function PropertyCard({ setSelectedProperty, handleOpen, property }) {
  const { title, surface, bathrooms, bedrooms, date, images } = property;

  return (
    <Grid item xs={12} sm={12} md={4}>
      <Card sx={{ position: 'relative' }}>

        <TableCell align="right">
          <IconButton size="" color="info" onClick={
            () => {
              setSelectedProperty(property)
              handleOpen()
            }
          }>
            <Iconify icon={'eva:more-vertical-fill'} />
          </IconButton>
        </TableCell>
        <StyledCardMedia
          sx={{
            ...({
              pt: 'calc(100% * 4 / 3)',
              '&:after': {
                top: 0,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              },
            }),
            ...({
              pt: {
                xs: 'calc(100% * 4 / 3)',
                sm: 'calc(100% * 3 / 4.66)',
              },
            }),
          }}
        >
          {images && <StyledCover
            key={images[0]?._id}
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg";
            }
            }
            src={`${process.env.REACT_APP_API_URL}/uploads/${images[0]?.path}`} alt={images[0]?.filename} />
          }
        </StyledCardMedia>

        <CardContent
          sx={{
            pt: 4,
            ...({
              bottom: 140,
              width: '100%',
              position: 'absolute',
            }),
          }}
        >
          <Typography gutterBottom variant="caption" sx={{ color: 'text.disabled', display: 'block' }}>
            {fDate(date)}
          </Typography>

          <Typography
            color="inherit"
            variant="subtitle2"
            sx={{
              color: 'text.disabled', display: 'block',
              fontSize: '1.2rem',
            }}
          >
            {title}
          </Typography>

        </CardContent>
        <div className='property-card'>
          <ul className='card-list'>
            <li className='card-item'>
              <div className='item-icon'>
                <ion-icon name='cube-outline'></ion-icon>
              </div>

              <span className='item-text'>{surface} m²</span>
            </li>

            <li className='card-item'>
              <div className='item-icon'>
                <ion-icon name='man-outline'></ion-icon>
              </div>

              <span className='item-text'>{bathrooms} Baths</span>
            </li>
            <li className='card-item'>
              <div className='item-icon'>
                <ion-icon name='bed-outline'></ion-icon>
              </div>

              <span className='item-text'>{bedrooms} Beds</span>

            </li>

            <li className='card-item'>
              <div className='item-icon'>
                <ion-icon name='row'></ion-icon>
              </div>
              <a href={`/productDetails/${property._id}`}>
                <span className='item-text'>View details</span>
              </a>
            </li>
          </ul>
        </div>

      </Card>

    </Grid>



  );
}
