import {
  ADD_CONTACT,
  DELETE_CONTACT,
  GET_CONTACTS,
} from "../actionsTypes/contactActionsType";

const initialState = {
  contact: {},
  contacts: [],
  successMessage: false,
  errors: false,
  isLoading: true,
};

export const contactReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS:
      return {
        ...state,
        contacts: action.payload.contacts,
        successMessage: action.payload.successMessage,
        errors: action.payload.errors,
        isLoading: action.payload.isLoading,
      };
    case ADD_CONTACT:
      return {
        ...state,
        contacts: [...state.contacts, action.payload.contact],
        successMessage: action.payload.successMessage,
        errors: action.payload.errors,
        isLoading: action.payload.isLoading,
      };
    case DELETE_CONTACT:
      return {
        ...state,
        contacts: [
          ...state.contacts.filter(
            (contact) => contact._id !== action.payload.id
          ),
        ],
      };
    case "CLOSE_NOTIFICATION":
      return {
        ...state,
        successMessage: action.payload.successMessage,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
};
