import React from 'react'
import { NavbarBrand } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faLinkedinIn,
  faFacebookF,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons'
import {
  faPhone,
  faEnvelope,
  faMapMarkerAlt,
} from '@fortawesome/fontawesome-free-solid'
import Logo from '../../Assets/logo.png'

export default function Footer() {
  const icons = [
    { icon: faFacebookF, path: '' },
    { icon: faInstagram, path: '' },
    {
      icon: faLinkedinIn,
      path: '',
    },
  ]

  if (
    window.location.href.includes('investIn') ||
    window.location.href.includes('otherServices')
  ) {
    return null
  }
  return (
    <footer className="container-fluid sub-footer">
      <div className="row">
        <div className="footer-link">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-2">
              <div className="col-md-4 col-lg-4 col-xl-3 mx-auto mb-4 d-flex row align-items-center justify-content-center ">
                <div className="navbar-translate col-6 ">
                  <NavbarBrand
                    data-placement="bottom"
                    style={{
                      fontWeight: 600,
                      color: 'white',
                      fontSize: 27,
                    }}
                    href="/"
                  >
                    <img src={Logo} alt="logo" width="100" />
                  </NavbarBrand>
                </div>
              </div>
              <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4 ">
                <h6 className="text-uppercase fw-bold mb-4 nav-Link footer-a text-start">
                  Links
                </h6>
                <div className="align-items-center">
                  <div className="footer-column">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a className="nav-link footer-a" href="/">
                          Home
                        </a>
                      </li>
                      <li className="nav-item ">
                        <a className="nav-link footer-a" href="/properties/all">
                          Properties
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link footer-a" href="/contactUs">
                          Contact Us
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-2 col-lg-3 col-xl-3 mx-auto mb-4 ">
                <h6 className="text-uppercase fw-bold mb-4 nav-Link footer-a text-start">
                  Contact
                </h6>
                <div className="align-items-center">
                  <div className="footer-column">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <a
                          className="nav-link footer-a"
                          href="mailto:saberhlf@worldwidease.com"
                        >
                          <FontAwesomeIcon icon={faEnvelope} color="white" />
                          {'  '}
                          saberhlf@worldwidease.com
                        </a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link footer-a" href="#location">
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            color="white"
                          />
                          {'  '}Turkey
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link footer-a"
                          href="tel:+905354293492"
                        >
                          <FontAwesomeIcon icon={faPhone} color="white" />
                          {'  '}+905354293492
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center justify-content-lg-between p-4 border-top">
                <div className="me-5 d-none d-lg-block white nav-Link footer-a">
                  <span>Get connected with us on social networks</span>
                </div>
                <div className="d-flex ft-16">
                  {icons.map((item, key) => (
                    <a
                      href={item.path}
                      className="me-4 text-reset"
                      key={key}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={item.icon} color="white" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
