import { CircularProgress, Grid } from "@mui/material";

export default function Spinner() {
    return (
        <Grid container mb={2}
            sx={{
                position: 'relative',
                width: '100%',
                height: '100%',
                minHeight: '50vh',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >

            <CircularProgress color="inherit" sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                bottom: '50%',
                width: '60px !important',
                height: '60px !important',
            }}
            />
        </Grid>
    )
}