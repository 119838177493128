import {
    ADD_PROPERTY,
    DELETE_PROPERTY,
    GET_PROPERTY,
    GET_PROPERTY_ID,
    UPDATE_PROPERTY,
    GET_PROPERTY_PUPOSE
} from "../actionsTypes/propertieActionsType";
import axiosFactory, {
    METHOD_DELETE,
    METHOD_GET,
    METHOD_POST,
    METHOD_PUT,
} from "./axiosFactory";

export const addPropertie = (images, propertyData, handleModal, handleSuccess) => async (dispatch
) => {
    try {
        const formData = new FormData();
        for (let i = 0; i < images.length; i++) {
            formData.append('files', images[i]);
        }
        formData.append('propertyData', JSON.stringify(propertyData));
        const { data, status, errors } = await axiosFactory({
            url: "/propertie/addPropertie",
            method: METHOD_POST,
            data: formData,
        });
        if (status === 200) {
            handleModal();
            handleSuccess();
            dispatch({
                type: ADD_PROPERTY,
                payload: {
                    successMessage: true,
                    data: data.data,
                    errors: false,
                },
            });
        } else {
            dispatch({
                type: "ERROR",
                payload: errors,
            });
        }

    } catch (error) {
        dispatch({
            type: "ERROR",
            payload: error,
        });
    }
};

export const getProperties = () => async (dispatch) => {
    try {
        const { status, data } = await axiosFactory({
            url: "/propertie/",
            method: METHOD_GET,
        });
        if (status === 200) {
            dispatch({
                type: GET_PROPERTY,
                payload: {
                    properties: data.data,
                    isLoading: false,
                },
            });
        }
    } catch (error) {
        dispatch({
            type: "Error",
            payload: error,
        });
    }
};
export const getPropertyById = (propertyId) => async (dispatch) => {
    try {
        const { status, data } = await axiosFactory({
            url: `/propertie/${propertyId}`,
            method: METHOD_GET,
        });
        if (status === 200) {
            dispatch({
                type: GET_PROPERTY_ID,
                payload: {
                    property: data.data,
                    isLoading: false,
                }
            });
        }
    } catch (error) {
        dispatch({
            type: "Error",
            payload: error,
        });
    }
};

export const getPropertiesByPrupose = (purpose) => async (dispatch) => {
    try {
        const { data } = await axiosFactory({
            url: `/propertie/prupose/${purpose}`,
            method: METHOD_GET,
        });
        dispatch({
            type: GET_PROPERTY_PUPOSE,
            payload: {
                properties: data.data,
                isLoading: false,

            }
        });
    } catch (error) {
        dispatch({
            type: "Error",
            payload: error,
        });
    }
};

export const deleteProperty = (propertyId, handleModal, handleSuccess) => async (dispatch, handleModal) => {
    try {
        // eslint-disable-next-line no-empty-pattern
        const { status } = await axiosFactory({
            url: `/propertie/deletePropertie/${propertyId}`,
            method: METHOD_DELETE,
        });

        if (status === 200) {
            handleModal();
            handleSuccess();
            dispatch({
                type: DELETE_PROPERTY,
                payload: {
                    id: propertyId,
                    successMessage: true,
                    isLoading: false,
                },
            });
            handleModal();
        }
    } catch (error) {
        dispatch({
            type: "Error",
            payload: {
                error: error,
                successMessage: false,
                isLoading: false,
            }

        });
    }
};

export const updateProperty = (propertyId, propertyData, handleModal, handleSuccess) => async (dispatch) => {
    try {
        const { status } = await axiosFactory({
            url: `/propertie/updatePropertie/${propertyId}`,
            method: METHOD_PUT,
            data: propertyData,
        });
        if (status === 200) {
            handleModal();
            handleSuccess();
            dispatch({
                type: UPDATE_PROPERTY,
                payload: {
                    property: propertyData,
                    id: propertyId,
                    successMessage: true,
                    errors: false,
                    isLoading: false,
                },
            });

        }
    } catch (error) {
        dispatch({
            type: "Error",
            payload: error,
        });
    }
};

export const closeNotification = (handleSuccess) => {
    handleSuccess();
    return {
        type: "CLOSE_NOTIFICATION",
        payload: {
            successMessage: false,
            errors: false,
        },
    }
}

