import { keyboard } from '@testing-library/user-event/dist/keyboard';
import { filter } from 'lodash';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_contact) => (_contact.firstname.toLowerCase().indexOf(query.toLowerCase()) !== -1) || (_contact.lastname.toLowerCase().indexOf(query.toLowerCase()) !== -1));
    }
    return stabilizedThis.map((el) => el[0]);
}
export function applyFilter(array, query) {
    return filter(array, (_data) => (_data.name.toLowerCase().indexOf(query.toLowerCase()) !== -1) || (_data.department.toLowerCase().indexOf(query.toLowerCase()) !== -1));
}
export function filterArr(array, query) {
    return filter(array, (_data) => (_data.title.toLowerCase().indexOf(query.toLowerCase()) !== -1));
}


export function firstLetterUpperCase(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function Unit(key, value) {
    switch (key) {
        case "surface":
            return `${value} m²`;
        case "price":
            return `${value} $`;
        default: 
            return value;
    }

}