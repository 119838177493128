import axios from "axios";

export const METHOD_GET = "GET";
export const METHOD_POST = "POST";
export const METHOD_PUT = "PUT";
export const METHOD_DELETE = "DELETE";

export default async function axiosFactory({ url, method, data }) {
  const urlBase = process.env.REACT_APP_API_URL + url;
  const header = getHeader(urlBase, method, data);
  let response = {};
  try {
    switch (method) {
      case METHOD_GET:
        response = await axios.get(urlBase, header);
        break;
      case METHOD_POST:
        if (data instanceof FormData) {
          header.headers["Content-Type"] = "multipart/form-data";
          header.headers["Accept"] = "multipart/form-data";
        }
        response = await axios.post(urlBase, data, header);
        break;
      case METHOD_PUT:
        response = await axios.put(urlBase, data, header);
        break;
      case METHOD_DELETE:
        response = await axios.delete(urlBase, header);
        break;
      default:
        new Error("Method not supported");
    }
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("token");
    }
    if (error.response) {
      response.error = error.response.data;
      response.status = error.response.status;
    } else if (error.request) {
      response.error = error.request;
    } else {
      response.error = "Network Error";
      response.status = 307;
    }
  }
  return {
    data: response.data,
    status: response.status,
    error: response.error,
  };
}

function getHeader(url, method, data) {
  const header = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  };
  if (!url.includes("/login")) {
    header.authorization = localStorage.getItem("token");
  }
  if (method === METHOD_DELETE) {
    return { headers: header, data };
  }
  return { headers: header };
}
