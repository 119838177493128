
import Image from "../Assets/noResults.png";
const NoResults = () => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <img src={Image} alt='404' width='200' />
        </div>
    )
}
export default NoResults