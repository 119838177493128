import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'
import { Button, Container, InputAdornment, Stack, OutlinedInput } from '@mui/material'
import { filterArr } from '../../Helpers/helper'
import {
    getProperties,
    closeNotification,
} from '../../Redux/actions/propertiesActions'
import Iconify from '../../Shared/iconify'
import { styled, alpha } from "@mui/material/styles";
import { AddProperty } from '../../Components/Properties'
import NoResults from '../../Components/NoResults'
import Notifications from '../../Components/Notifications'
import Spinner from '../../Components/Spinner/Spinner'
import PropertiesList from './PropertiesList'

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(["box-shadow", "width"], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    "&.Mui-focused": {
        width: 320,
    },
    "& fieldset": {
        borderWidth: `1px !important`,
        borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
    },
}));
export default function Properties() {
    const [open, setOpen] = useState({
        showAddProperty: false,
        showEdit: false,
        showDelete: false,
    })
    const [popper, setPopper] = useState(false)
    const [filterName, setFilterName] = useState('')
    const [selectedProperty, setSelectedProperty] = useState({})
    const [success, setSuccess] = useState(false)

    const dispatch = useDispatch()
    const properties = useSelector((state) => state.propertiesReducer.properties)
    const isLoading = useSelector((state) => state.propertiesReducer.isLoading)
    const filteredEvent = filterArr(properties, filterName)

    const handlePopper = (event) => {
        setPopper(!popper)
    }

    const handleSuccess = () => {
        setSuccess(!success)
    }


    const handleModal = (action) =>
        setOpen({
            ...open,
            [`show${action}`]: !open[`show${action}`],
        })

    const handleFilterByName = (event) => {
        setFilterName(event.target.value)
    }

    useEffect(() => {
        dispatch(getProperties(dispatch))
    }, [dispatch])

    return (
        <Container>
            {success && <Notifications
                isOpen={success}
                message="Success"
                handleSuccess={handleSuccess}
                closeNotification={closeNotification}
            />}
            <AddProperty
                open={open.showAddProperty}
                handleSuccess={handleSuccess}
                handleClose={() => handleModal('AddProperty')}
            />
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={5}
                mt={10}
            >
                <Stack>
                    <StyledSearch
                        value={filterName}
                        onChange={handleFilterByName}
                        placeholder="Search by name"
                        startAdornment={
                            <InputAdornment position="start">
                                <Iconify
                                    icon="eva:search-fill"
                                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                                />
                            </InputAdornment>
                        }
                    />
                </Stack>
                <Button
                    variant="contained"
                    startIcon={<Iconify icon="eva:plus-fill" />}
                    onClick={() => handleModal('AddProperty')}
                    sx={{
                        background: 'hsl(224, 34%, 13%)',
                    }}
                >
                    New Property
                </Button>
            </Stack>
            {isLoading ? (
                <Spinner />
            ) : isEmpty(filteredEvent) ? (
                <NoResults />
            ) : (
                <PropertiesList
                    filteredEvent={filteredEvent}
                    handlePopper={handlePopper}
                    popper={popper}
                    handleModal={handleModal}
                    open={open}
                    handleSuccess={handleSuccess}
                    selectedProperty={selectedProperty}
                    dispatch={dispatch}
                    setSelectedProperty={setSelectedProperty}
                />
            )}
        </Container>
    )
}
