import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addContact } from "../Redux/actions/contactActions";
import Notifications from "../Components/Notifications";
import { closeNotification } from '../Redux/actions/contactActions';
const ContactUsPage = ({ isDetails }) => {
  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState({
    fullName: "",
    phoneNumber: "",
    email: "",
    subject: "",
    content: "",
  });

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const success = useSelector(state => state.contactReducer.successMessage)



  return (
    <>
      {
        success && <Notifications isOpen={success} message="Your message has been sent successfully"
          closeNotification={closeNotification}
        />
      }
      <div className={`contact-us-container container ${isDetails ? 'mt-4' : 'mrg-top-150'}`}>
        <h1 className="title">Contact Us</h1>
        <p className="subtitle">
          We'd love to hear from you! Please fill out the form below and we'll get
          back to you as soon as possible.
        </p>
        <form className="contact-form" onSubmit={(e) => {
          e.preventDefault();
          dispatch(addContact(formValues, dispatch))
          setFormValues({
            firstname: "",
            phoneNumber: "",
            email: "",
            subject: "",
            content: "",
          })
        }}>
          <div className="input-group">
            <label htmlFor="firstname">Full Name</label>
            <input
              type="text"
              name="firstname"
              value={formValues.firstname}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="tel"
              name="phoneNumber"
              value={formValues.phoneNumber}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              name="subject"
              value={formValues.subject}
              onChange={handleChange}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="content">Message</label>
            <textarea
              name="content"
              value={formValues.content}
              onChange={handleChange}
              required
            ></textarea>
          </div>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </>
  );
};

export default ContactUsPage;
