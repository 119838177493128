import { styled } from "@mui/material/styles";
import { Container, Typography } from "@mui/material";
import { LoginForm } from "../Components/Login";

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  backgroundColor: "#fff",
  padding: theme.spacing(12, 0),
}));

export default function LoginPage() {
  return (
    <StyledRoot>
      <Container>
        <StyledContent>
          <Typography variant='h4' gutterBottom>
            Sign in
          </Typography>
          <LoginForm />
        </StyledContent>
      </Container>
    </StyledRoot>
  );
}
