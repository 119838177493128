import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteContact, getContacts } from '../../Redux/actions/contactActions';
import {
    Card,
    Table,
    Stack,
    Avatar,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    Box,
} from '@mui/material';


import ContactListHead from '../../Components/ContactDashbord/ContactHead';
import ThemeProvider from '../../theme';
import Scrollbar from '../../Shared/scrollbar/Scrollbar';
import Iconify from '../../Shared/iconify/Iconify';
import DeleteModal from '../../Components/DeleteModal';
import { isEmpty } from 'lodash';
import NoResults from '../../Components/NoResults';
import Spinner from '../../Components/Spinner/Spinner';

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'message', label: 'Message', alignRight: false },
    { id: 'phone', label: 'Phone', alignRight: false },
    { id: '' }
];

export default function Contacts() {
    const [page, setPage] = useState(0);
    const [selectedContact, setSelectedContact] = useState({});
    const [open, setOpen] = useState(false);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('name');
    const [rowsPerPage, setRowsPerPage] = useState(12);

    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.contactReducer.isLoading)
    const contacts = useSelector((state) => state.contactReducer.contacts);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - contacts.length) : 0;

    const handleModal = () => setOpen(!open);
    const selectContact = (contact) => {
        setSelectedContact(contact);
        handleModal();

    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };



    useEffect(() => {
        dispatch(getContacts(dispatch));
    }, [dispatch]);

    return (
        <>
            {isLoading ? <Spinner /> :
                isEmpty(contacts) ?
                    <Box sx={{ width: '100%', mt: 20, mb: 20 }}>
                        <Container>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                <Typography variant="h4" gutterBottom
                                    sx={{
                                        color: 'text.secondary',
                                        ml: 5
                                    }}
                                >
                                    Contacts
                                </Typography>

                            </Stack>
                        </Container>
                        <NoResults />
                    </Box>
                    : (
                        <ThemeProvider>

                            <Container
                                sx={{
                                    marginTop: 20,
                                    marginBottom: 35
                                }}
                            >
                                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                                    <Typography variant="h4" gutterBottom
                                        sx={{
                                            color: 'text.secondary',
                                            ml: 5
                                        }}
                                    >
                                        Contacts
                                    </Typography>

                                </Stack>

                                <Card>
                                    <Scrollbar>
                                        <TableContainer sx={{ minWidth: 800 }}>
                                            <Table>
                                                <ContactListHead
                                                    order={order}
                                                    orderBy={orderBy}
                                                    headLabel={TABLE_HEAD}
                                                    onRequestSort={handleRequestSort}
                                                />
                                                {<TableBody>
                                                    {contacts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                                        const { _id, firstname, content, email, avatarUrl, phoneNumber } = row;

                                                        return (
                                                            <TableRow hover key={_id} tabIndex={-1}>
                                                                <TableCell padding="checkbox" />

                                                                <TableCell component="th" scope="row" padding="none">
                                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                                        <Avatar alt={firstname} src={avatarUrl} />
                                                                        <Typography variant="subtitle2" noWrap>
                                                                            {firstname}
                                                                        </Typography>
                                                                    </Stack>
                                                                </TableCell>

                                                                <TableCell align="left">{email}</TableCell>

                                                                <TableCell align="left">{content}</TableCell>

                                                                <TableCell align="left">{phoneNumber}</TableCell>

                                                                <TableCell align="right" sx={{ color: 'error.main' }}>
                                                                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} onClick={() => {
                                                                        selectContact(row);
                                                                    }}
                                                                    />
                                                                </TableCell>


                                                            </TableRow>
                                                        );
                                                    })}
                                                    {emptyRows > 0 && (
                                                        <TableRow style={{ height: 53 * emptyRows }}>
                                                            <TableCell colSpan={6} />
                                                        </TableRow>
                                                    )}
                                                </TableBody>}
                                                <DeleteModal open={open} handleClose={() => handleModal(
                                                )}
                                                    handleDelete={
                                                        () => {
                                                            dispatch(deleteContact(selectedContact._id, dispatch))
                                                        }
                                                    }
                                                />

                                            </Table>
                                        </TableContainer>
                                    </Scrollbar>

                                    <TablePagination
                                        rowsPerPageOptions={[12, 24, 48]}
                                        component="div"
                                        count={contacts.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Card>
                            </Container>


                        </ThemeProvider>
                    )
            }
        </>
    );
}
