import { Link } from "react-router-dom";
import "./Property.css";
const ProductItem = ({ product }) => {
  return (
    <Link to={`/productDetails/${product._id}`}>
      <li>
        <div className='property-card property-card-items'>
          <figure
            className='card-banner img-holder'
            style={{
              height: "150px",
            }}
          >
            {product.images && < img
              src={`${process.env.REACT_APP_API_URL}/uploads/${product.images[0]?.path}`}
              width='800'
              height='433'
              loading='lazy'
              alt={product.name}
              className='img-cover'
            />}
          </figure>

          <div className='card-content'>
            <h3 className='h3'>
              <p className='card-title'>
                {product.title} - {product.location}
              </p>
            </h3>

            <ul className='card-list'>
              <li className='card-item'>
                <div className='item-icon'>
                  <ion-icon name='cube-outline'></ion-icon>
                </div>

                <span className='item-text'>{product.surface} m²</span>
              </li>

              <li className='card-item'>
                <div className='item-icon'>
                  <ion-icon name='bed-outline'></ion-icon>
                </div>

                <span className='item-text'>{product.rooms} Beds</span>
              </li>

              <li className='card-item'>
                <div className='item-icon'>
                  <ion-icon name='man-outline'></ion-icon>
                </div>

                <span className='item-text'>{product.bathrooms} Baths</span>
              </li>
            </ul>

            <div className='card-meta'>
              <div>
                <span className='meta-title'>Price</span>

                <span className='meta-text'>${product.price}</span>
              </div>

            </div>
          </div>
        </div>
      </li>
    </Link>
  );
};

export default ProductItem;
