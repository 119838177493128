import { AUTH_ERROR, LOGIN, LOGOUT } from "../actionsTypes/authActionsTypes";
import axiosFactory, { METHOD_GET, METHOD_POST } from "./axiosFactory";

export const login = (userData, navigate) => async (dispatch) => {
  try {
    const { status, data } = await axiosFactory({
      url: "/login",
      method: METHOD_POST,
      data: userData,
    });
    if (status === 200) {
      dispatch({
        type: LOGIN,
        payload: {
          isAuthenticated: true,
          user: data,
          successMessage: "Success",
          errors: false,
        },
      });
      localStorage.setItem("token", data.token);
      navigate("/contactList", { replace: true });
    } else {
      dispatch({
        type: AUTH_ERROR,
        payload: {
          errors: "Invalid email or password",
        },
      });
    }
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error,
    });
  }
};

export const logout = (navigate) => (dispatch) => {
  localStorage.clear();
  navigate("/");
  dispatch({
    type: LOGOUT,
  });
};

export const currentUser = () => async (dispatch) => {
  try {
    const res = await axiosFactory({
      url: "/current",
      method: METHOD_GET,
    });

    dispatch({
      type: LOGIN,
      payload: {
        isAuthenticated: true,
        user: res.data.user,
        successMessage: "Success",
        errors: false,
      },
    });
  } catch (error) {
    dispatch({
      type: AUTH_ERROR,
      payload: error,
    });
  }
};
