import {
  ADD_PROPERTY,
  DELETE_PROPERTY,
  GET_PROPERTY,
  GET_PROPERTY_ID,
  GET_PROPERTY_PUPOSE,
  UPDATE_PROPERTY,
} from "../actionsTypes/propertieActionsType";

const initialState = {
  property: {},
  properties: [],
  successMessage: false,
  errors: false,
  isLoading: true,
};

export const propertiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROPERTY:
      return {
        ...state,
        properties: action.payload.properties,
        successMessage: action.payload.successMessage,
        errors: action.payload.errors,
        isLoading: action.payload.isLoading,
      };
    case GET_PROPERTY_ID:
      return {
        ...state,
        property: action.payload.property,
        isLoading: action.payload.isLoading,
      };
    case ADD_PROPERTY:
      return {
        ...state,
        properties: [...state.properties, action.payload.data],
        successMessage: action.payload.successMessage,
        errors: action.payload.errors,
        isLoading: action.payload.isLoading,
      };
    case DELETE_PROPERTY:
      return {
        ...state,
        properties: [
          ...state.properties.filter(
            (property) => property._id !== action.payload.id
          ),
        ],
      };
    case UPDATE_PROPERTY:
      const index = state.properties.findIndex(
        (property) => property._id === action.payload.id
      );
      if (index !== -1) {
        const properties = [
          ...state.properties.slice(0, index),
          action.payload.property,
          ...state.properties.slice(index + 1),
        ];
        return {
          ...state,
          properties: properties,
        };
      }
      return state;

    case "ERROR":
      return {
        ...state,
        errors: action.payload,
      };
    case GET_PROPERTY_PUPOSE:
      return {
        ...state,
        properties: action.payload.properties,
        successMessage: action.payload.successMessage,
        errors: action.payload.errors,
        isLoading: action.payload.isLoading,
      };
    case 'CLOSE_NOTIFICATION':
      return {
        ...state,
        successMessage: action.payload.successMessage,
        errors: action.payload.errors,
      };
    default:
      return state;
  }
};
