import Swipper from "../Components/Caroussel/Swipper";
import HeroSection from "../Components/HeroSection";
import useResponsive from "../Helpers/responsive";
import ContactUs from "../Components/Contact";
import { Link } from "react-router-dom";
import { SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { useDispatch, useSelector } from "react-redux";
import { getProperties } from "../Redux/actions/propertiesActions";
import { useEffect } from "react";
import { isEmpty } from "lodash";

const HomePage = () => {
  const isMobile = useResponsive();
  const dispatch = useDispatch();
  const properties = useSelector((state) => state.propertiesReducer.properties);
  useEffect(() => {
    dispatch(getProperties(dispatch));
  }, [dispatch]);
  return (
    <>
      <HeroSection />
      <section className="section property" aria-label="property">
        <div className="container">
          <h2 className="h2 section-title pb-4">How It Works</h2>


          <ul className="service-list pt-2">
            <li>
              <div className="service-card">
                <div className="card-icon">
                  <ion-icon name="home-outline"></ion-icon>
                </div>

                <h3 className="h3 card-title">Sale</h3>
              </div>
            </li>

            <li>
              <div className="service-card">
                <div className="card-icon">
                  <ion-icon name="briefcase-outline"></ion-icon>
                </div>

                <h3 className="h3 card-title">Rent</h3>
              </div>
            </li>

            <li>
              <div className="service-card">
                <div className="card-icon">
                  <ion-icon name="key"></ion-icon>
                </div>

                <h3 className="h3 card-title">Close the Deal</h3>
              </div>
            </li>
          </ul>
        </div>
      </section>
      {
        !isEmpty(properties) && (

          <section className="section property" aria-label="property">
            <div className="container">
              <h2 className="h2 section-title">Featured Properties</h2>
              {isMobile ? (
                <Swipper>
                  <div className="popular__container grid swiper">
                    {properties.map((product, index) => {
                      return (

                        <SwiperSlide
                          key={index}
                          className='properties-container pt-2'
                        >
                          <Link to={`/productDetails/${product._id}`}>
                            <article className='popular__card swiper-slide'>
                              {product.images && < img
                                src={`${process.env.REACT_APP_API_URL}/uploads/${product.images[0]?.path}`}
                                alt=""
                                onError={(e) => {
                                  e.target.onerror = null;
                                  e.target.src =
                                    "https://www.generationsforpeace.org/wp-content/uploads/2018/03/empty.jpg";
                                }}
                                className="popular__img"
                                style={{ height: "300px", borderRadius: "10px" }}
                              />}

                              <div className="popular__data">
                                <h2
                                  className="popular__price"
                                  style={{ display: "flex", pt: "2px" }}
                                >
                                  <span>$</span>
                                  {product.price}
                                </h2>
                                <h3 className="popular__title">{product.name}</h3>
                                <p className="popular__description">
                                  {product.address}
                                </p>
                              </div>
                            </article>
                          </Link>
                        </SwiperSlide>
                      );
                    })}
                  </div>
                </Swipper>
              ) : (
                <section id="popular">
                  <div className="container">
                    <div className="popular__container d-flex swiper">
                      {properties.map((product) => (
                        <Link to={`/productDetails/${product._id}`}>
                          <article className="popular__card swiper-slide">

                            {product.images && <img
                              src={`${process.env.REACT_APP_API_URL}/uploads/${product.images[0]?.path}`}
                              alt={product.name}
                              className="popular__img"
                              style={{ borderRadius: "10px", height: "150px" }}
                            />}

                            <div
                              className="popular__data"
                              style={{ color: "black" }}
                            >
                              <h2
                                className="popular__price"
                                style={{ display: "flex" }}
                              >
                                ${product.price}
                              </h2>
                              <h3 className="popular__title">{product.name}</h3>
                              <p className="popular__description">
                                {product.address}
                              </p>
                            </div>
                          </article>
                        </Link>
                      ))}
                    </div>
                  </div>
                </section>
              )}
            </div>
          </section>
        )
      }
      <ContactUs isHomePage />
    </>
  );
};
export default HomePage;
