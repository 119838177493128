import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Container, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { updateProperty } from '../../Redux/actions/propertiesActions';
import { ModalTitle } from 'react-bootstrap';
import Iconify from '../../Shared/iconify/Iconify';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    maxWidth: {
        xs: '100%',
        sm: '100%',
        md: '100%',
        lg: '100%'
    },
    p: 4,
    overflowY: "scroll",
    maxHeight: {
        xs: '400px',
        sm: '600px',
        md: '400px',
        lg: '400px',
    }
};

EditProperty.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    property: PropTypes.object.isRequired
};


export default function EditProperty({ property, open, handleClose, handleSuccess }) {
    const dispatch = useDispatch();
    const formik = useFormik({
        initialValues: {
            description: property.description,
            rooms: property.rooms,
            floors: property.floors,
            bathrooms: property.bathrooms,
            bedrooms: property.bedrooms,
            location: property.location,
            price: property.price,
            surface: property.surface,
            title: property.title,
            purpose: property.purpose,
            images: property.images

        },
        validationSchema: Yup.object({
            description: Yup.string().required("Required"),
            rooms: Yup.number().required("Required"),
            floors: Yup.number().required("Required"),
            bathrooms: Yup.number().required("Required"),
            bedrooms: Yup.number().required("Required"),
            location: Yup.string().required("Required"),
            price: Yup.number().required("Required"),
            surface: Yup.string().required("Required"),
            title: Yup.string().required("Required"),
            purpose: Yup.string().required("Required"),
        }),
        onSubmit: (values) => {
            dispatch(updateProperty(property._id, values, handleClose, handleSuccess, dispatch))
        },
    });
    if (!open) { return null; }
    return (
        <Modal
            open={open}
            onClose={() => {
                handleClose();
                formik.resetForm();
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                component="main"
                sx={style}
            >
                <Container maxWidth="sm">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ModalTitle id="modal-modal-title">Edit : {formik.values.title}</ModalTitle>
                        <Iconify icon="eva:close-outline" onClick={() => handleClose()} />
                    </Box>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            error={Boolean(formik.touched.title && formik.errors.title)}
                            fullWidth
                            helperText={formik.touched.title && formik.errors.title}
                            label='Title'
                            margin='normal'
                            name='title'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            variant='outlined'
                        />
                        <TextField
                            error={Boolean(formik.touched.rooms && formik.errors.rooms)}
                            fullWidth
                            helperText={formik.touched.rooms && formik.errors.rooms}
                            label='Rooms'
                            margin='normal'
                            name='rooms'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.rooms}
                            variant='outlined'
                        />
                        <TextField
                            error={Boolean(formik.touched.floors && formik.errors.floors)}
                            fullWidth
                            helperText={formik.touched.floors && formik.errors.floors}
                            label='Floors'
                            margin='normal'
                            name='floors'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.floors}
                            variant='outlined'
                        />
                        <TextField
                            error={Boolean(formik.touched.bathrooms && formik.errors.bathrooms)}
                            fullWidth
                            helperText={formik.touched.bathrooms && formik.errors.bathrooms}
                            label='Bathrooms'
                            margin='normal'
                            name='bathrooms'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.bathrooms}
                            variant='outlined'
                        />
                        <TextField
                            error={Boolean(formik.touched.bedrooms && formik.errors.bedrooms)}
                            fullWidth
                            helperText={formik.touched.bedrooms && formik.errors.bedrooms}
                            label='Bedrooms'
                            margin='normal'
                            name='bedrooms'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.bedrooms}
                            variant='outlined'
                        />
                        <TextField
                            error={Boolean(formik.touched.location && formik.errors.location)}
                            fullWidth
                            helperText={formik.touched.location && formik.errors.location}
                            label='Location'
                            margin='normal'
                            name='location'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.location}
                            variant='outlined'
                        />
                        <TextField
                            error={Boolean(formik.touched.price && formik.errors.price)}
                            fullWidth
                            helperText={formik.touched.price && formik.errors.price}
                            label='Price'
                            margin='normal'
                            name='price'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.price}
                            variant='outlined'
                        />
                        <TextField
                            error={Boolean(formik.touched.surface && formik.errors.surface)}
                            fullWidth
                            helperText={formik.touched.surface && formik.errors.surface}
                            label='Surface'
                            margin='normal'
                            name='surface'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.surface}
                            variant='outlined'
                        />
                        <TextField
                            error={Boolean(formik.touched.purpose && formik.errors.purpose)}
                            fullWidth
                            helperText={formik.touched.purpose && formik.errors.purpose}
                            label='Purpose'
                            margin='normal'
                            name='purpose'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.purpose}
                            variant='outlined'
                        />
                        <TextField
                            error={Boolean(formik.touched.description && formik.errors.description)}
                            fullWidth
                            helperText={formik.touched.description && formik.errors.description}
                            label='Description'
                            margin='normal'
                            name='description'
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            variant='outlined'
                        />
                        <Box sx={{
                            display: 'flex', justifyContent: 'space-arround'
                            , flexWrap: 'wrap'
                        }}>
                            {formik.values.images.map((image, index) => (
                                <img key={index} src={`${process.env.REACT_APP_API_URL}/uploads/${image.path}`} alt={`Selected ${index}`}
                                    width={100}
                                    height={100}
                                />
                            ))}
                        </Box>


                        <Box sx={{ py: 2 }}>
                            <Button
                                sx={{
                                    background: "hsl(224, 34%, 13%)"
                                }}
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                            >
                                Edit property
                            </Button>
                        </Box>
                    </form>
                </Container>
            </Box>
        </Modal>
    );
}
