import { Route, Routes, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import NavBar from "./Components/NavBar";
import Footer from "./Components/Footer";
import HomePage from "./Pages/HomePage";
import AllProducts from "./Pages/AllProducts";
import ContactUsPage from "./Pages/ContactUs";
import ProductDetails from "./Pages/ProductDetails";
import Page404 from "./Pages/Page404";
import UnderConstruction from "./Pages/UnderConstruction";
import LoginPage from "./Pages/LoginPage";
import Properties from "./Pages/Dashboard/Properties";
import Contacts from "./Pages/Dashboard/Contacts";
import PrivateRoutes from "./Pages/PrivateRoutes";

function App() {
  const [colorAppBackground, setcolorAppBackground] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (
      location.pathname === "/investIn" ||
      location.pathname === "/otherServices"
    ) {
      setcolorAppBackground(true);
    } else {
      setcolorAppBackground(false);
    }
  }, [location.pathname]);
  return (
    <div className={colorAppBackground ? "App blue-back" : "App"}>
      <NavBar />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='properties/:purpose' element={<AllProducts />} />
        <Route path='properties/' element={<AllProducts />} />
        <Route path='/contactUs' element={<ContactUsPage />} />
        <Route path='/productDetails/:id' element={<ProductDetails />} />
        <Route path='/investIn' element={<UnderConstruction />} />
        <Route path='/otherServices' element={<UnderConstruction />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/contactList' element={
          <PrivateRoutes>
            <Contacts />
          </PrivateRoutes>
        } />
        <Route path='/dashboard/properties' element={
          <PrivateRoutes>
            <Properties />
          </PrivateRoutes>
        } />
        <Route path='*' element={<Page404 />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
