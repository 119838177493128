import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Box, Button, Container, MenuItem, TextField } from '@mui/material';
import Modal from '@mui/material/Modal';
import { addPropertie } from '../../Redux/actions/propertiesActions';
import { useState } from 'react';
import Iconify from '../../Shared/iconify/Iconify';
import { ModalTitle } from 'react-bootstrap';

const style = {
  position: 'absolute',
  top: '55%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
  maxHeight: '460px',
};



const FileInput = ({ field, handleImages, ...props }) => {
  const handleChange = (event) => {
    const files = Array.from(event.target.files);
    handleImages(files);
  };



  return (
    <input
      type="file"
      onChange={handleChange}
      multiple
      accept="image/*"
      {...field}
      {...props}
    />
  );
};

AddProperty.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
};


export default function AddProperty({ open, handleClose, handleSuccess }) {
  const dispatch = useDispatch();
  const [images, setImages] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  const handleImages = (files) => {
    setImages([...images, ...files]);
    setSelectedImages([...selectedImages, ...files.map(file => URL.createObjectURL(file))]);
  };
  const errors = useSelector((state) => state.propertiesReducer.errors);
  const formik = useFormik({
    initialValues: {
      description: "",
      rooms: 0,
      floors: 0,
      bathrooms: 0,
      bedrooms: 0,
      location: '',
      price: 0,
      surface: 0,
      title: "",
      purpose: "",
      images: []

    },
    validationSchema: Yup.object({
      description: Yup.string().required("Required"),
      rooms: Yup.number().required("Required"),
      floors: Yup.number().required("Required"),
      bathrooms: Yup.number().required("Required"),
      bedrooms: Yup.number().required("Required"),
      location: Yup.string().required("Required"),
      price: Yup.number().required("Required"),
      surface: Yup.string().required("Required"),
      title: Yup.string().required("Required"),
      purpose: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {

      dispatch(addPropertie(images, values, handleClose, handleSuccess
        , dispatch));
      formik.resetForm();
      setSelectedImages([]);
      setImages([]);
    },
  });

  const purposeOptions = [
    {
      value: "sale",
      label: "Sale",
    },
    {
      value: "shortTerm",
      label: "Rent for short term",
    },
    {
      value: "longTerm",
      label: "Rent for long term",
    },
  ];


  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setSelectedImages([]);
        setImages([]);
        formik.resetForm();
      }
      }
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box component="main" sx={style}>
        <Container>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <ModalTitle id="modal-modal-title">Add Property</ModalTitle>
            <Iconify icon="eva:close-outline" onClick={() => handleClose()} />
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              error={Boolean(formik.touched.title && formik.errors.title)}
              fullWidth
              helperText={formik.touched.title && formik.errors.title}
              label='Title'
              margin='normal'
              name='title'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.title}
              variant='outlined'
            />
            <TextField
              error={Boolean(formik.touched.rooms && formik.errors.rooms)}
              fullWidth
              helperText={formik.touched.rooms && formik.errors.rooms}
              label='Rooms'
              margin='normal'
              name='rooms'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.rooms}
              variant='outlined'
            />
            <TextField
              error={Boolean(formik.touched.floors && formik.errors.floors)}
              fullWidth
              helperText={formik.touched.floors && formik.errors.floors}
              label='Floors'
              margin='normal'
              name='floors'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.floors}
              variant='outlined'
            />
            <TextField
              error={Boolean(formik.touched.bathrooms && formik.errors.bathrooms)}
              fullWidth
              helperText={formik.touched.bathrooms && formik.errors.bathrooms}
              label='Bathrooms'
              margin='normal'
              name='bathrooms'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.bathrooms}
              variant='outlined'
            />
            <TextField
              error={Boolean(formik.touched.bedrooms && formik.errors.bedrooms)}
              fullWidth
              helperText={formik.touched.bedrooms && formik.errors.bedrooms}
              label='Bedrooms'
              margin='normal'
              name='bedrooms'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.bedrooms}
              variant='outlined'
            />
            <TextField
              error={Boolean(formik.touched.location && formik.errors.location)}
              fullWidth
              helperText={formik.touched.location && formik.errors.location}
              label='Location'
              margin='normal'
              name='location'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.location}
              variant='outlined'
            />
            <TextField
              error={Boolean(formik.touched.price && formik.errors.price)}
              fullWidth
              helperText={formik.touched.price && formik.errors.price}
              label='Price'
              margin='normal'
              name='price'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.price}
              variant='outlined'
            />
            <TextField
              error={Boolean(formik.touched.surface && formik.errors.surface)}
              fullWidth
              helperText={formik.touched.surface && formik.errors.surface}
              label='Surface'
              margin='normal'
              name='surface'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.surface}
              variant='outlined'
            />
            <TextField
              error={Boolean(formik.touched.purpose && formik.errors.purpose)}
              fullWidth
              helperText={formik.touched.purpose && formik.errors.purpose}
              label='Purpose'
              margin='normal'
              name='purpose'
              select
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.purpose}
              variant='outlined'
            >
              {purposeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              error={Boolean(formik.touched.description && formik.errors.description)}
              fullWidth

              helperText={formik.touched.description && formik.errors.description}
              label='Description'
              margin='normal'
              multiline
              name='description'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.description}
              variant='outlined'
            />
            <FileInput name="images" form={formik} handleImages={handleImages} />

            <Box sx={{
              display: 'flex', justifyContent: 'space-arround'
              , flexWrap: 'wrap'
            }}>
              {selectedImages.map((image, index) => (
                <img key={index} src={image} alt={`Selected ${index}`}
                  width={100}
                  height={100}
                />
              ))}
            </Box>

            <p>{errors}</p>
            <Button
              sx={{
                background: "hsl(224, 34%, 13%)"
              }}
              disabled={images.length === 0}
              fullWidth
              size='large'
              type='submit'
              variant='contained'
            >
              Add Property
            </Button>
          </form>
        </Container>
      </Box>
    </Modal>
  );
}



