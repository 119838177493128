import { useEffect, useState } from "react";
import ProductList from "../Components/Products/ProductList";
import ScrollTop from "../Components/ScrollTop";
import SearchBar from "../Components/SearchBar/Search";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getProperties, getPropertiesByPrupose } from "../Redux/actions/propertiesActions";
import { isEmpty } from "lodash";
import NoResults from "../Components/NoResults";
import Spinner from "../Components/Spinner/Spinner";

const AllProducts = () => {
  const [searchText, setSearchText] = useState("");
  const [sortby, setSortby] = useState("low-to-high");

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.propertiesReducer.isLoading);
  const results = useSelector((state) => state.propertiesReducer.properties);
  const { purpose } = useParams()

  useEffect(() => {
    if (isEmpty(purpose)) {
      dispatch(getProperties(dispatch));
    } else {
      dispatch(getPropertiesByPrupose(purpose));
    }
  }, [dispatch, purpose]);

  switch (sortby) {
    case "price-ascending":
      results.sort((a, b) => parseInt(a.price) - parseInt(b.price));
      break;
    case "price-descending":
      results.sort((a, b) => parseInt(b.price) - parseInt(a.price));
      break;
    case "newest":
      results.reverse();
      break;
    default:
      break;
  }

  const filteredResults = results.filter((result) => result.title.toLowerCase().includes(searchText.toLowerCase()));


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <ScrollTop>
      <section className='section hero' aria-label='hero'>
        <div className='container'>
          <div className='hero-bg'>
            <div className='hero-content'>
              <h1
                className='h1 hero-title'
                style={{
                  fontSize: "45px",
                }}
              >
                We will help you find your{" "}
                <span className='span'>Wonderful</span> home
              </h1>
            </div>
          </div>

          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            setSortby={setSortby}

          />
        </div>
      </section>

      <section className='section property' aria-label='property'>
        <div className='container'>
          {isLoading ? (
            <Spinner />
          ) : isEmpty(filteredResults) ? (
            <NoResults />
          ) : (
            <ProductList productsList={filteredResults} />
          )}
        </div>
      </section>
    </ScrollTop>
  );
};

export default AllProducts;
