export const data = [
  {
    icon: 'bx bxs-phone-call',
    title: 'Turkey',
    description: '+905354293492',
    cta: 'Call Now',
  },
  {
    icon: 'bx bxs-message-rounded-dots',
    title: 'Chat',
    description: '+905354293492',
    cta: 'Chat Now',
  },
  {
    icon: 'bx bxs-video',
    title: 'Qatar',
    description: '+97433647006',
    cta: 'Video Call Now',
  },
  {
    icon: 'bx bxs-envelope',
    title: 'Message',
    description: '+97433647006',
    cta: 'Send Now',
  },
]
