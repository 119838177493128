import { useParams } from "react-router-dom";
import ScrollTop from "../Components/ScrollTop";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyById } from "../Redux/actions/propertiesActions";
import { isEmpty } from "lodash";
import NoResults from "../Components/NoResults";
import ContactUsPage from "./ContactUs";
import { Carousel } from "react-bootstrap";
import Spinner from "../Components/Spinner/Spinner";
import { Unit, firstLetterUpperCase } from "../Helpers/helper";
import { Divider, List, ListItem, ListItemText } from "@mui/material";
const ProductDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.propertiesReducer.isLoading);
  const style = {
    width: '100%',
    maxWidth: 360,
    bgcolor: 'background.paper',
  };
  useEffect(() => {
    dispatch(getPropertyById(id, dispatch));
  }, [dispatch, id]);
  const product = useSelector((state) => state.propertiesReducer.property);
  const productArray = Object.keys(product).map((key) => {
    return {
      label: key,
      value: Unit(key,product[key]),
    };
  }).filter((item) => {
    if (item.label === "images" ||
      item.label === "date" ||
      item.label === "_id" ||
      item.label === "__v" ||
      item.label === "description" || item.label === "title" || item.label === "location"
    ) {
      return false;
    }
    return true;
  });

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="container">
          <ScrollTop>
            {isEmpty(product) ? (
              <NoResults />) : (
              <div className="contact-us-container single-product"
                style={{
                  marginTop: "50px"
                }}
              >

                <Carousel
                  style={{
                    borderRadius: "10px",
                    height: "490px",
                    overflow: "hidden",

                  }}
                >
                  {product.images && product.images.map((image, index) => {
                    return (
                      <Carousel.Item key={index} className="pt-2">
                        <article className="">
                          <img
                            src={`${process.env.REACT_APP_API_URL}/uploads/${image?.path}`}
                            alt={image._id}
                          />
                        </article>
                      </Carousel.Item>
                    );
                  }
                  )
                  }

                </Carousel>
                <div className="right-side">
                  <div>
                    <h1 className="display-5 pt-4 pb-4 c-blue">{firstLetterUpperCase(product.title)} - {
                      product.location
                    }</h1>
                    <p
                      style={
                        {
                          textAlign: "justify",
                          width: '100%',
                        }}
                      className="card-text"
                    >
                      {
                        product.description
                      }
                    </p>
                    <List sx={style} component="nav" aria-label="mailbox folders">

                      {
                        productArray.map((item, key) => {
                          console.log(item)
                          return (<>
                            <ListItem button key={key}>
                              <ListItemText primary={`${firstLetterUpperCase(item.label)}`} />
                              <>{item.value}</>
                            </ListItem>
                            <Divider />
                          </>

                          )
                        })

                      }
                    </List>
                  </div>
                  {/* <div className="row">
                    <div className="container">
                      <div className="details-card">
                        <div className="d-flex properties-details">
                          <div>
                            <p className="card-text">Bedrooms</p>
                            <i className="bx bxs-bed"></i> {product.rooms}
                          </div>
                          <div>
                            <p className="card-text">Bathrooms</p>
                            <i className="bx bxs-bath"></i> {product.bathrooms}{" "}
                          </div>

                          <div>
                            <p className="card-text">Price</p>
                            <i className="bx bxs-dollar-circle"></i>
                            {product.price}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>

              </div>)}
            <ContactUsPage isDetails />
          </ScrollTop >
        </div>
      )
      }
    </>
  );
};

export default ProductDetails;
