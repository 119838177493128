import {
  ADD_CONTACT,
  DELETE_CONTACT,
  GET_CONTACTS,
} from "../actionsTypes/contactActionsType";
import axiosFactory, {
  METHOD_DELETE,
  METHOD_GET,
  METHOD_POST,
} from "./axiosFactory";

export const addContact = (contactData) => async (dispatch) => {
  try {
    const { status, errors } = await axiosFactory({
      url: "/contact/addContact",
      method: METHOD_POST,
      data: contactData,
    });
    if (status === 200) {
      dispatch({
        type: ADD_CONTACT,
        payload: {
          contact: contactData,
          successMessage: true,
        }
      });
    } else {
      dispatch({
        type: "ERROR",
        payload: errors,
      });
    }
  } catch (error) {
    dispatch({
      type: "ERROR",
      payload: error,
    });
  }
};

export const getContacts = () => async (dispatch) => {
  try {
    const { status, data } = await axiosFactory({
      url: "/contact/",
      method: METHOD_GET,
    });
    if (status === 200) {
      dispatch({
        type: GET_CONTACTS,
        payload: {
          contacts: [...data.data],
        },
        isLoading: false,
      });
    }
  } catch (error) {
    dispatch({
      type: "Error",
      payload: error,
    });
  }
};

export const deleteContact = (contactId) => async (dispatch) => {
  try {
    const { status } = await axiosFactory({
      url: `/contact/deleteContact/${contactId}`,
      method: METHOD_DELETE,
    });
    if (status === 200) {
      dispatch({
        type: DELETE_CONTACT,
        payload: {
          id: contactId,
          successMessage: "Success",
          errors: false,
        },
      });
    }
  } catch (error) {
    dispatch({
      type: "Error",
      payload: error,
    });
  }
};


export const closeNotification = () => {
  return {
    type: "CLOSE_NOTIFICATION",
    payload: {
      successMessage: false,
      errors: false,
    },
  }
}