import { Grid } from "@mui/material";
import { PropertyCard } from "../../Components/Properties";
import Crud from "./Crud";

export default function PropertiesList({
    filteredEvent,
    handlePopper,
    popper,
    handleModal,
    open,
    selectedProperty,
    dispatch,
    handleSuccess,
    setSelectedProperty
}) {
    return <>
        <Grid container spacing={3} mb={4}>
            {filteredEvent.map((property, key) => (
                    <PropertyCard key={key} property={property}
                        handleOpen={() => handlePopper()} setSelectedProperty={setSelectedProperty}
                        index={key} propertyLength={filteredEvent.length} />
            ))}
        </Grid>
        <Crud
            popper={popper}
            handlePopper={handlePopper}
            handleModal={handleModal}
            open={open}
            handleSuccess={handleSuccess}
            selectedProperty={selectedProperty}
            dispatch={dispatch}
        />
    </>
}